<template>
  <div
    :class="['flex flex-row items-center', context.classes.element]"
    :data-type="context.type"
  >
    <div class="w-10">
      <select
        v-model="hh"
        v-bind="context.attributes"
        @blur="context.blurHandler"
        @change="setTime($event.target.value, 'hh')"
      >
        <option disabled value="">hh</option>
        <option
          v-for="hr in hours"
          :key="hr"
          :value="hr.toString().padStart(2, '0')"
        >
          {{ hr.toString().padStart(2, '0') }}
        </option>
      </select>
    </div>
    <div class="mx-1">:</div>
    <div class="w-10">
      <select
        v-model="mm"
        v-bind="context.attributes"
        @blur="context.blurHandler"
        @change="setTime($event.target.value, 'mm')"
      >
        <option disabled value="">mm</option>
        <option
          v-for="min in minutes"
          :key="min"
          :value="min.toString().padStart(2, '0')"
        >
          {{ min.toString().padStart(2, '0') }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInputTimePicker',
  props: {
    context: {
      type: Object,
      required: true
    },
    hours: {
      type: Array,
      default: () => {
        return Array.from(Array(24).keys());
      }
    },
    minutes: {
      type: Array,
      default: () => {
        return Array.from(Array(60).keys());
      }
    }
  },
  data() {
    return {
      hh: '',
      mm: ''
    };
  },
  computed: {
    model: {
      get() {
        return this.context.model;
      },
      set(val) {
        this.context.model = val;
      }
    }
  },
  watch: {
    // This handles downstream values from the parent component
    model: {
      immediate: true,
      handler(value, oldValue) {
        // Don't update if the values are the same
        if (value !== oldValue) {
          if (!value) {
            // If the value is not valid, we reset the hh mm
            this.hh = '';
            this.mm = '';
            return;
          }

          const [hours, minutes] = value.split(':');
          this.hh = hours;
          this.mm = minutes;
        }
      }
    }
  },
  methods: {
    setTime(time, type) {
      const hh = type === 'hh' ? time : this.hh;
      const mm = type === 'mm' ? time : this.mm;

      this.model = `${hh}:${mm}`;
    }
  }
};
</script>

<style scoped>
select,
select > option {
  @apply p-0;
  @apply appearance-none;
  @apply bg-none;
  @apply text-center;
  @apply text-base;
  text-align: -webkit-center;
  text-align-last: center;
}
</style>
